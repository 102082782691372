import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import { primary_color, secondary_color } from '../../assets/colors';
import styled from 'styled-components';
import { Galaxi } from "../../assets/index";

const Navbar = () => {
  const navigate = useNavigate(); 

  return (
    <>
      <NavDiv>
        <Div40>
        <Link to="/"><Logo src={Galaxi} /></Link>
          <Button
            onClick={() => navigate(-1)} 
          >
            Home
          </Button>
        </Div40>
      </NavDiv>
    </>
  )
}

const NavDiv = styled.div`
  width: 100%;
  box-shadow: 0px 3px 10px 0px #00000040;
  background: ${secondary_color};
  position: fixed;
  z-index: 100;
  padding: 20px;
`;

const Div40 = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 150px;

  @media (max-width: 750px) {
    width: 100px;
  }
`;

const Button = styled.li`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${primary_color};
  cursor: pointer;
  list-style: none;
  margin: 0px 5px;

  @media (max-width: 750px) {
    font-size: 13px;
    padding: 8px 12px;
  }
`;

export default Navbar;
