// BlogGrid.tsx
import React from 'react';
import styled from 'styled-components';
import {
  green_color,
  primary_color,
  secondary_color,
} from "../../assets/colors";

interface BlogItemProps {
  date: string;
  title: string;
  imageSrc: string;
  link: string;
}

const BlogGrid: React.FC = () => {
  const blogItems: BlogItemProps[] = [
    {
      date: 'Jun 25, 2024',
      title: 'The AI-Powered Stock Screener Revolutionizing Long-Term Investing',
      imageSrc: 'https://miro.medium.com/v2/format:webp/1*MSFTP0WRnIcA5GRuTeW12g.jpeg',
      link: 'https://medium.com/@QIEecosystem/galaxi-invest-the-ai-powered-stock-screener-revolutionizing-long-term-investing-4b65de047084'
    },
    {
      date: 'Jun 24, 2024',
      title: 'The AI-Powered Superhero of Stock Strategies',
      imageSrc: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*EPAye9dzfYgkTK9JGUauNA.jpeg',
      link: 'https://medium.com/@QIEecosystem/galaxi-invest-the-ai-powered-superhero-of-stock-strategies-e58247a0269a'
    },
    {
      date: 'Jun 24, 2024',
      title: 'Galaxi Invest: Your Stock-Screening Superhero',
      imageSrc: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*MSFTP0WRnIcA5GRuTeW12g.jpeg',
      link: 'https://medium.com/@QIEecosystem/galaxi-invest-your-stock-screening-superhero-1000e01190e4'
    },
    {
      date: 'Jun 13, 2024',
      title: 'The AI-Powered Stock Screener Revolutionizing Investment Strategies',
      imageSrc: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*HkowwbK42vl6p8lzDBM8kA.png',
      link: 'https://medium.com/@QIEecosystem/galaxi-invest-the-ai-powered-stock-screener-revolutionizing-investment-strategies-55124faf9131'
    },
  ];

  return (
    <Container>
      <Header>Latest Blog</Header>
      <Grid>
        {blogItems.map((item, index) => (
          <BlogCard key={index}>
            <Image src={item.imageSrc} alt={item.title} />
            <Date>{item.date}</Date>
            <Title>{item.title}</Title>
            <ReadMore><a href={item.link} target='_blank'>Read More</a></ReadMore>
          </BlogCard>
        ))}
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  color: ${primary_color};
  text-align: center;
`;

const Header = styled.h2`
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  color: ${primary_color};
`;

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding: 2rem;
`;

const BlogCard = styled.div`
  background-color: ${primary_color};
  color: ${secondary_color};
  border-radius: 10px;
  overflow: hidden;
  width: calc(25% - 20px);
  position: relative;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const Date = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${green_color};
  color: ${secondary_color};
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
`;

const Title = styled.h3`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  color: ${secondary_color};
`;

const ReadMore = styled.button`
  background: ${green_color};
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  font-size: 1rem;
  text-align: center;
  padding: 10px;
  &:hover {
    opacity: 0.8;
  }

  a {
    color: ${secondary_color};
    text-decoration: none; /* Remove underline from link */
    display: inline-block; /* Necessary for text-decoration to work */
    width: 100%; /* Make the link cover the button area */
  }
`;

export default BlogGrid;
