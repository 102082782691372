import React from "react";
import { styled } from "styled-components";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from './routes/root'
import Strategies from "./routes/strategies";
import Payment from "./screens/payment";
import LogIn from "./screens/login";
import ErrorPage from "./error-page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/strategies",
    element: <Strategies />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/payment/*",
    element: <Payment />,
  },
  {
    path: "/delete-me",
    element: <LogIn />,
  },
]);
function App() {
  return (
    <Body>
      <RouterProvider router={router} />
    </Body>
  );
}

export default App;

const Body = styled.div`
  width: 100%;
  height: 100vh;
`;
