import React, { useRef } from "react";
import styled from "styled-components";
import { primary_color, secondary_color } from "../../assets/colors";

const strategies = [
  {
    number: "01",
    title: "Quality Stock Investment Strategy: Unveiling Robust Microcap Opportunities",
    description: "Introducing a long-only Quality-adjusted Value microcap investment strategy, meticulously developed and tested by O'Shaughnessy Asset Management. Over a remarkable 34-year span, this strategy has consistently delivered a compelling annual return of 20.3%.",
    cards: [
      {
        title: "1. Change in Net Operating Assets (NOA):",
        description: "NOA, measuring a company's required assets for operational efficiency, reveals unique insights. Microcap companies within our universe exhibited an average NOA change of 44.3%, emphasizing their ability to sustain organic growth without excessive debt. The top 10% with the most significant NOA increases were excluded."
      },
      {
        title: "2. Debt to Equity Ratio:",
        description: "Addressing concerns over financial health, we eliminate the top 10% of microcap companies with the highest debt to equity ratio, ensuring a prudent approach to debt management."
      },
      {
        title: "3. 1-Year Change in Debt:",
        description: "A 32.6% average one-year change in debt highlights the delicate balance between financing operations and rapid growth. Companies experiencing the most substantial debt increase (top 10%) are excluded from our universe."
      },
      {
        title: "4. Return on Invested Capital (ROIC):",
        description: "ROIC is a pivotal metric, gauging a company's efficacy in turning capital into profits. By excluding the bottom 10% with the lowest ROIC, our strategy aligns with prudent investment in profitable projects."
      },
      {
        title: "5. Free Cash Flow Yield:",
        description: "Assessing a company's sustainability, the Free Cash Flow Yield measures cash available after deducting capital expenditure, relative to market capitalization. Exclusion of the bottom 10% with the lowest Free Cash Flow yield ensures resilience and independence."
      }
    ],
    span: "This quality-focused strategy unveils microcap opportunities with a proven track record, offering investors a pathway to consistent and robust returns."
  },
  {
    number: "02",
    title: "Value Investing Strategy: Unlocking Undervalued Opportunities",
    description: (
      <>
        Our Value Investment strategy mirrors the legendary Warren Buffett's approach, leveraging six key valuation ratios for a comprehensive assessment:
        <ul>
          <li>Price to Book Value</li>
          <li>Price to Sales</li>
          <li>Earnings before Interest, Taxes, Depreciation, and Amortization to Enterprise Value (EBITDA / EV)</li>
          <li>Price to Cash Flow</li>
          <li>Price to Earnings</li>
          <li>Shareholder Yield (Dividend Yield + Percentage of Shares Repurchased)</li>
        </ul>
        The efficacy of this strategy lies in its holistic approach. This single indicator facilitates the identification of undervalued companies through diverse metrics such as cash flow, book value, sales, EBITDA, and cash returned to shareholders.
      </>
    ),
    cards: [
      {
        title: "Calculation Process:",
        description: (<>1. Rank each company against others based on all valuation ratios. <br /> 2. Aggregate individual rankings into a combined ranking for each company. <br /> 3.Rank companies by this combined ranking and group them in percentiles (1 to 100), where 1 represents the most undervalued and 100 the most expensive.</>)
      }, 
      {
        title: "2. Proven Performance:",
        description: "Back-tested data reveals impressive results, with a remarkable +21.2% annual returns over 45 years in USD terms (Dec 1963 - Dec 2009). This enduring success underscores the strategy's effectiveness in identifying and capitalizing on undervalued investment opportunities."
      }
    ]
  },
  {
    number: "03",
    title: "Momentum Investment Strategy: Capturing Growth with Precision",
    description: "Executing this investment strategy involves a meticulous selection process. Initially, identify the top 20% of companies with the most substantial 6-month share price increase, known as momentum. From this elite group, strategically acquire those with the lowest Price to Book ratio.",
    cards: [
      {
        title: "1. Performance Metrics:",
        description: "This dynamic strategy has demonstrated exceptional results, boasting an impressive annualized return of 23.5% over 12 years. The cumulative effect over this period amounts to an astounding 1152%, emphasizing the strategy's compounding strength."
      },
      {
        title: "2. Price Index (PI) Ratio - A Momentum Indicator:",
        description: "Momentum, also referred to as the Price Index (PI) ratio, gauges a company's upward moving momentum by evaluating the current share price relative to its position six months ago. This ratio serves as a powerful tool to uncover promising investment opportunities."
      },
      {
        title: "3. Tested and Proven:",
        description: "Backed by extensive research and testing over prolonged periods, momentum strategies consistently outperform the market. Richard Driehaus, a renowned figure in investment circles, has championed the effectiveness of momentum investing as a means to harness the growth trajectory of a company's stock price."
      },
      {
        title: "4. Historical Data Reliability:",
        description: "Our approach relies solely on historical accounting data, eschewing the use of forecasts. Extensive evidence supports this decision, as forecasts have proven unreliable in numerous studies. David Dreman's notable work in 'The New Contrarian Investment Strategy' underscores the frequent inaccuracies of analyst estimates, with an average error rate of 40%. By combining a 6-month PI with low Price to Book value companies, we not only mitigate the shortcomings of individual ratios but also enhance overall returns. The strategic fusion of momentum and valuation ensures a robust approach that stands the test of time."
      }
    ]
  },
  {
    number: "04",
    title: "Growth Stock Investment Strategy: Unlocking Hidden Gems in Microcap Markets",
    description: "This strategy focuses on identifying high-growth potential stocks within the microcap sector. By analyzing growth metrics and market trends, we seek out undervalued stocks with significant upside potential.",
    cards: [
      {
        title: "1. The \"Junkyard\" Microcap Universe:",
        description: (
          <>
            Microcap stocks, encompassing companies with a market value ranging from $50 million to $200 million, have long dwelled in the shadows, perceived as a "junkyard" by institutional investors. This perception is rooted in two prevalent beliefs:
            <ul>
              <li>Professional money managers, alongside most investors, often categorize microcap equities as high-risk, weak entities with poor financial health.</li>
              <li>The small size of these companies renders them inaccessible to major investors managing billions of dollars, further contributing to the perception of them being overlooked.</li>
            </ul>
          </>
        )
      },
      {
        title: "2. Strategic Formula:",
        description: "Our Growth Stock Investment Strategy challenges this narrative by focusing on the overlooked potential within the microcap universe. The strategy utilizes a calculated formula, considering the highest three-year growth in earnings per share (EPS), the highest return on equity (ROE), and robust sales growth over the same period."
      },
      {
        title: "3. Inspiration from Thomas Rowe Price, Jr.:",
        description: "In the spirit of investment pioneers like Thomas Rowe Price, Jr., we recognize the value in identifying and investing in companies that may be deemed unconventional or overlooked. Thomas Rowe Price, Jr. himself revolutionized the field of growth investing, emphasizing the importance of understanding a company's potential for future earnings and growth."
      },
    ]
  },
  {
    number: "05",
    title: "Galaxi Magic Formula Quants Strategy: Unveiling the Enigma",
    description: "Embark on a journey into the extraordinary realm of Galaxi Magic Formula Quants Strategy — a clandestine concoction representing the pinnacle of the world's finest trading strategies. Crafted with utmost precision, this unique intellectual property, meticulously nurtured by Galaxi Pty Ltd over years of dedication, is the brainchild of a seasoned team of fund managers boasting over 40 years of proven back-tested prowess.",
    cards: [
      {
        title: "1. Why is it Special?",
        description: "This strategy is more than just a portfolio; it's the culmination of secrets and insights, an arcane alchemy that unveils itself only to those curious enough to explore its depths. It thrives on the belief that within the market's intricate tapestry, there are always mispriced stocks waiting to be unraveled and capitalized upon."
      },
      {
        title: "2. Exceptional Results Await:",
        description: "While this portfolio may engage in shorting and selectively use leverage, such actions are reserved for exceptional circumstances, typically when the market experiences pronounced undervaluation or overvaluation. The aim? To achieve results that transcend the ordinary, to leave a mark of exceptionalism."
      },
      {
        title: "3. Strategic Safeguards:",
        description: "Every portfolio under this strategy operates within carefully delineated geographical, currency, and industry parameters. These limitations serve a dual purpose — mitigating the inherent risks associated with portfolios and ensuring optimal diversification. The goal is to eliminate unsystematic risk as far as possible, creating a portfolio that stands resilient against market vicissitudes."
      },
    ]
  }
];

const StrategySteps = () => {
  const stepRefs = useRef<(HTMLDivElement | null)[]>([]);

  const scrollToNextStep = (index: number) => {
    if (stepRefs.current[index + 1]) {
      stepRefs.current[index + 1]?.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Container>
        {strategies.map((strategy, index) => (
        <StepContainer key={index} ref={(el) => (stepRefs.current[index] = el)}>
          <Step>
            <Header>
              <Number>{strategy.number}</Number>
              <div>
                <Title>{strategy.title}</Title>
                <Description>{strategy.description}</Description>
              </div>
            </Header>
            {/* <Next onClick={() => scrollToNextStep(index)}>See the next strategy ↓</Next> */}
            {strategy.cards && (
              <CardContainer>
                {strategy.cards.map((card, idx) => (
                  <Card key={idx}>
                    <div>
                      <Title1>{card.title}</Title1>
                      <Description1>{card.description}</Description1>
                    </div>
                  </Card>
                ))}
              </CardContainer>
            )}
          </Step>
        </StepContainer>
      ))}
      </Container>
    </>
  );
};


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 0 20px; 
  box-sizing: border-box;

  @media (max-width: 768px) {
    gap: 30px; 
    padding: 0 10px;
  }
`;

const Step = styled.div`
  width: 100%;
`;

const StepContainer = styled.div`
  padding: 70px;
  padding-bottom: 0px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 40px 20px; 
  }
`;

const Number = styled.h3`
  font-family: Montserrat;
  font-size: 70px;
  font-weight: 600;
  color: ${primary_color};
  margin: 0;

  @media (max-width: 768px) {
    font-size: 50px;
  }
`;

const Title = styled.h1`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  color: ${primary_color};
  margin-top: 20px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 24px; 
    line-height: 32px;
  }
`;

const Title1 = styled.h1`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  color: ${secondary_color};
  margin: 0;

  @media (max-width: 768px) {
    font-size: 18px; 
  }
`;

const Description = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  margin-top: 20px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 14px; 
    line-height: 20px;
  }
`;

const Description1 = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${secondary_color};
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px; 
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: fit-content;

  @media (max-width: 768px) {
    flex-direction: column; 
    text-align: center;
  }
`;

const Next = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  color: ${primary_color};
  margin-top: 20px;
  cursor: pointer;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 14px; 
  }
`;

const Card = styled.div`
  /* width: 100%; */
  background: linear-gradient(90deg, rgba(29,23,82,1) 0%, rgba(191,184,255,1) 100%);
  padding: 20px;
  border-radius: 10px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 15px; 
  }
`;
export default StrategySteps;
