import React from 'react'
import styled from 'styled-components';
import { primary_color, secondary_color } from '../assets/colors';
import StrategySteps from '../components/strategy';
import Footer from '../screens/footer';
import TryFreeSection from '../screens/tryFree';
import Navbar from '../components/strategy/Navbar';


const strategies = () => {
  return (
    <>
      <Navbar />
      <Container>
        <ContainerWrapper>
          <Title>Strategies to Win</Title>
        </ContainerWrapper>
      </Container>
      <StrategySteps />
      <TryFreeSection />
      <Footer />
    </>
  )
}

const Container = styled.div`
  width: 100%;
  background: ${primary_color};
  /* position: relative; */
  /* padding: 0px 5%; */
`;
const ContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 26em;
  @media (max-width: 750px) {
    height: 12em;
  }
`;

const Title = styled.h1`
  font-family: Montserrat;
  font-size: 50px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: ${secondary_color};
  @media (max-width: 750px) {
    text-align: center;
    font-size: 30px;
  }
`;

export default strategies